<template>
 <PageHeading
  :button-title="$t('callqueue.buttonTitle')"
  :has-meta-data="false"
  :has-search="true"
  :has-action-button="true"
  :title="$t('callQueue')"
  :has-permission="
   userPermissions.permissions.includes('smart-routings.*') ||
   userPermissions.permissions.includes('smart-routings.create')
  "
  @open="(this.openForm = !this.openForm), (this.openUpdateForm = false)"
  @update:search="$emit('update:search', $event)"
 />
 <div class="flex flex-col" v-if="this.openForm">
  <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
   <div class="pt-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
    <div class="rounded overflow-hidden border border-gray-200">
     <CreateNewCallQueue
      :title="$t('callqueue.newFormTitle')"
      :subTitle="$t('callqueue.formSubTitle')"
      :callQueuesGroups="this.callQueuesGroups"
      :editData="editData"
      :openUpdateForm="openUpdateForm"
      @activeBanner="$emit('activeBanner'), getCallQueuesLists()"
      @successMsg="$emit('successMsg', $event)"
      @success="$emit('success', $event)"
      @closeForm="this.openForm = false"
     />
    </div>
   </div>
  </div>
 </div>
 <div class="flex flex-col" v-if="this.openUpdateForm">
  <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
   <div class="pt-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
    <div class="rounded overflow-hidden shadow">
     <CreateNewCallQueue
      :title="$t('callqueue.updateFormTitle')"
      :subTitle="$t('callqueue.formSubTitle')"
      :callQueuesGroups="this.callQueuesGroups"
      :editData="editData"
      :openUpdateForm="openUpdateForm"
      @activeBanner="$emit('activeBanner'), getCallQueuesLists()"
      @successMsg="$emit('successMsg', $event)"
      @success="$emit('success', $event)"
      @closeForm="this.openUpdateForm = false"
     />
     <!--   <UpdateCallQueueForm
      v-on:closeForm="this.openUpdateForm = false"
      :title="$t('callqueue.updateFormTitle')"
      :id="this.callQueueId"
      :name="this.callQueueName"
      :callQueuesGroups="this.callQueuesGroups"
      @activeBanner="
       (activeBanner = true), getCallQueuesLists(), (key = !key), (this.openUpdateForm = false)
      "
      @successMsg="successMsg = $event"
      @success="isSuccess = $event"
     /> -->
    </div>
   </div>
  </div>
 </div>

 <div class="flex flex-col">
  <div class="-my-0 sm:-mx-6 lg:-mx-0">
   <div
    v-if="callQueuesLists?.length > 0"
    class="align-middle inline-block min-w-full sm:px-6 lg:px-10"
   >
    <CallQueuesTable
     :account="this.account"
     :thead="thead"
     :tbody="filterCallQueuesLists()"
     @openEditForm="openEditForm($event)"
     @deleteList="(...args) => deleteList(...args)"
     @activeBanner="$emit('activeBanner')"
     @successMsg="$emit('successMsg', $event)"
     @success="$emit('success', $event)"
     @sort="(this.currentSort = $event), sort($event)"
    />
   </div>

   <div v-else class="justify-center pt-20">
    <EmptyState
     :title="$t('emptyStateTitle', { title: $t('callQueue') })"
     :subtitle="$t('emptyStateSubtitle')"
    />
   </div>
  </div>
 </div>
</template>

<script>
import axios from "axios";
import { PlusIcon } from "@heroicons/vue/solid";
import TablePagination from "../components/TablePagination.vue";
import CallQueuesHeader from "../components/CallQueuesHeader.vue";
import SearchFilter from "../components/SearchFilter.vue";
import CreateNewCallQueue from "../components/CreateNewCallQueue.vue";
import UpdateCallQueueForm from "../components/UpdateCallQueueForm.vue";
import SuccessBanner from "../components/SuccessBanner.vue";
import CallQueuesTable from "../components/CallQueuesTable.vue";
import EmptyState from "../components/EmptyState.vue";
import PageHeading from "../components/PageHeading.vue";

const account = localStorage.getItem("account");
const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

export default {
 props: ["search"],
 components: {
  EmptyState,
  PageHeading,
  PlusIcon,
  TablePagination,
  CallQueuesHeader,
  CallQueuesTable,
  SearchFilter,
  CreateNewCallQueue,
  UpdateCallQueueForm,
  SuccessBanner,
 },
 data() {
  return {
   thead: [
    { name: "callqueueTable.name", sort: "name", isSort: false },
    { name: "callqueueTable.number", sort: "number", isSort: false },
   ],
   callQueuesLists: [],
   callQueuesGroups: [],
   callQueueData: {},
   account,
   openForm: false,
   currentSort: "id",
   currentSortDir: "asc",
   callQueueId: "",
   callQueueName: "",
   openUpdateForm: false,
   key: false,
   userPermissions,
  };
 },
 methods: {
  openEditForm(data) {
   this.openForm = false;
   this.openUpdateForm = true;
   this.callQueueId = data.id;
   this.editData = data;
   window.scrollTo(0, 100);
  },
  async getCallQueuesLists() {
   this.$emit("setLoading", true);
   const res = await axios.get(
    `${this.$cookie.getCookie("API")}/api/v1/callQueues?customerAccount=${this.account}`,
    {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    }
   );

   this.$emit("setLoading", false);

   try {
    this.callQueuesLists = res.data;
   } catch (error) {
    this.$emit("setLoading", false);
    this.errorHandling(error);
   }
  },
  async getCallQueuesGroups() {
   this.$emit("setLoading", true);
   const res = await axios.get(
    `${this.$cookie.getCookie("API")}/api/v1/callQueueGroups?customerAccount=${this.account}`,
    {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    }
   );

   this.$emit("setLoading", false);

   try {
    this.callQueuesGroups = res.data;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  sort(s) {
   if (s === this.currentSort) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSort = s;
  },
  sortedCallQueues() {
   return this.callQueuesLists.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (toBeSorted === "number") {
     if (parseFloat(a[toBeSorted]) < parseFloat(b[toBeSorted])) return -1 * modifier;
     if (parseFloat(a[toBeSorted]) > parseFloat(b[toBeSorted])) return 1 * modifier;
    } else if (typeof a[toBeSorted] === "string") {
     if (a[toBeSorted].toLowerCase() < b[toBeSorted].toLowerCase()) return -1 * modifier;
     if (a[toBeSorted].toLowerCase() > b[toBeSorted].toLowerCase()) return 1 * modifier;
    }
    return 0;
   });
  },
  filterCallQueuesLists() {
   return this.sortedCallQueues().filter((res) => {
    if (this.search) {
     const searchLower = this.search.toLowerCase();

     const nameMatch = res.name.toLowerCase().includes(searchLower);
     const numberMatch = res.number.toLowerCase().includes(searchLower);

     return nameMatch || numberMatch;
    }
    return res;
   });
  },
 },
 mounted() {
  this.getCallQueuesLists();
  this.getCallQueuesGroups();
 },
};
</script>

<style></style>
